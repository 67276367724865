import React from 'react';

const IconLoader = () => (
  <svg
    id="logo"
    width="257"
    height="257"
    viewBox="0 0 257 257"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 128.474C0 57.5204 57.5318 5.76973e-05 128.5 5.76973e-05C199.468 5.76973e-05 257 57.5204 257 128.474C257 199.428 199.468 256.948 128.5 256.948C57.5318 256.948 0 199.428 0 128.474Z"
      fill="#151515"
      className="logo-svg-1"></path>
    <path
      d="M166.116 121.763L63.7614 62.6803C61.807 61.5519 59.3641 62.9627 59.3641 65.2185V183.384C59.3641 185.64 61.807 187.05 63.7614 185.922L166.116 126.839C168.07 125.711 168.07 122.891 166.116 121.763Z"
      fill="white"
      className="logo-svg-2"></path>
    <path
      d="M90.4298 153.535L192.784 212.617C194.739 213.745 197.182 212.335 197.182 210.08V91.9148C197.182 89.6579 194.739 88.2481 192.784 89.3756L90.4298 148.458C88.4754 149.587 88.4754 152.407 90.4298 153.535Z"
      fill="#89EB5B"
      className="logo-svg-3"></path>
  </svg>
);

export default IconLoader;
